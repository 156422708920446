import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import {
  AuthenticationFirstResponse,
  AuthenticationFinalResponse,
  User,
  CompanyUser,
  MasterLicense,
} from "../_models/user_authentication";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HeadsetService {
  constructor(private http: HttpClient) {}

  getHeadsets(company_id: number | null) {
    if (company_id) {
      return this.http
        .get<any>(
          `${environment.authUrl}/v1/company/headsets/available/${company_id}/`
        )
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || "Something went wrong!"))
        );
    } else {
      return this.http
        .get<any>(`${environment.authUrl}/v1/company/headsets/available/`)
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((error) => throwError(error || "Something went wrong!"))
        );
    }
  }

  getAssignedHeadsets(company_id: number, limit: number, offset: number, query: any = "") {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/headsets/assigned/${company_id}/?limit=${limit}&offset=${offset}&query=${query}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || "Something went wrong!"))
      );
  }

  getIssuedHeadsets(company_id: number, limit: number, offset: number, query: any = "") {
    return this.http
      .get<any>(
        `${environment.authUrl}/v1/company/headsets/issue/${company_id}/?limit=${limit}&offset=${offset}`
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || "Something went wrong!"))
      );
  }

  issueHeadset(
    name: string,
    headset_id: number,
    address_id: number,
    address_1: string,
    address_2: string,
    address_3: string,
    address_4: string,
    postcode: string,
    country: string,
    company_id: number,
    user_id: number
  ) {
    return this.http
      .post<any>(`${environment.authUrl}/v1/company/headsets/issue/`, {
        name,
        headset_id,
        address_id,
        address_1,
        address_2,
        address_3,
        address_4,
        postcode,
        country,
        company_id,
        user_id,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => throwError(error || "Something went wrong!"))
      );
  }
}
