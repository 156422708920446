<div style="display: flex; flex-direction: column;">
  <div fxFlex="100" style="display: flex; justify-content: space-between">
    <div fxFlex="50">
      <form class="d-flex row m-l-10" (ngSubmit)="applySearch()">
        <mat-form-field>
          <mat-label>Search headset by name, ordered, headset, status or carrier </mat-label>
          <input matInput style="padding-bottom: 1px" type="text" [(ngModel)]="searchIssuedHeadsets"
            name="searchIssuedHeadsets" />
          <button *ngIf="searchIssuedHeadsets" type="button" matSuffix mat-icon-button aria-label="Clear"
            (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="m-l-10 d-flex align-items-center">
          <button type="submit" mat-raised-button color="primary" style="margin-right: 10px">
            Search
          </button>
        </div>
      </form>
    </div>
  </div>

  <div fxFlex="100">
    <mat-progress-bar mode="indeterminate" class="m-t-20" *ngIf="loading"></mat-progress-bar>
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource || []" matSort class="table employee-list no-wrap">
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <div>
                <p class="font-medium m-b-0 m-t-0">
                  {{ element.shipping_address.name }}
                </p>
                <small class="text-muted">{{ element.shipping_address.address_1 }},
                  {{ element.shipping_address.postcode }}</small>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ordered">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Ordered</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date_created | date : "dd/MM/yy HH:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="headset">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Headset</th>
          <td mat-cell *matCellDef="let element">
            {{ element.headset_name.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status === 'shipped'">Shipped</ng-container><ng-container
              *ngIf="element.status !== 'shipped'">{{
              element.status
              }}</ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="carrier">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Carrier</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.carrier_name.id">{{ element.carrier_name.name }} -
              {{ element.tracking_reference }}</ng-container><ng-container
              *ngIf="!element.carrier_name.id">-</ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="[10, 25, 50]" (page)="pageChanged($event)" #userPag></mat-paginator>
    </div>
  </div>
</div>