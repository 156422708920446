<div fxLayout="row wrap">

  <div fxFlex="100" style="display: flex; justify-content: space-between">
    <div fxFlex="50">
      <form class="d-flex row m-l-10" (ngSubmit)="applySearch()">
        <mat-form-field>
          <mat-label>Search subdomain by domain, company, restricted, dedicated, server or created </mat-label>
          <input matInput style="padding-bottom: 1px" type="text" [(ngModel)]="searchSubDomain" name="searchSubDomain" />
          <button *ngIf="searchSubDomain" type="button" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="m-l-10 d-flex align-items-center">
          <button type="submit" mat-raised-button color="primary" style="margin-right: 10px">
            Search
          </button>
        </div>
      </form>
    </div>
  </div>

  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="responsive-table">
      <mat-table [dataSource]="dataSource || []" matSort>
        <ng-container matColumnDef="subdomain">
          <mat-header-cell *matHeaderCellDef> Sub Domain </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.url.split(".")[0] }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="domain">
          <mat-header-cell *matHeaderCellDef> Domain </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ getDomainType(element.url) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="restricted">
          <mat-header-cell *matHeaderCellDef> Restricted </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <i class="fa fa-check text-danger" *ngIf="element.restricted"></i>
            <i class="fa fa-times" *ngIf="!element.restricted"></i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="custom">
          <mat-header-cell *matHeaderCellDef> Dedicated</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <i class="fa fa-check text-danger" *ngIf="element.custom"></i>
            <i class="fa fa-times" *ngIf="!element.custom"></i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.date_created | date : "dd/MM/yy" }}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
      </mat-table>
        <mat-paginator
          [length]="totalRows"
          [pageIndex]="currentPage"
          [pageSize]="pageSize"
          [pageSizeOptions]="[10, 25, 50]"
          (page)="pageChanged($event)"
        ></mat-paginator>
    </div>
  </div>
</div>
