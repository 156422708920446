<div fxLayout="row wrap">
	<div fxFlex="100" style="display: flex; justify-content: space-between">
		<div fxFlex="50">
			<form class="d-flex row m-l-10" (ngSubmit)="applySearch()">
				<mat-form-field>
					<mat-label>Search headset by headset, date, quantity ordered, quantity used or quantity remaining </mat-label>
					<input matInput style="padding-bottom: 1px" type="text" [(ngModel)]="searchAssignedHeadsets"
						name="searchAssignedHeadsets" />
					<button *ngIf="searchAssignedHeadsets" type="button" matSuffix mat-icon-button aria-label="Clear"
						(click)="clearSearch()">
						<mat-icon>close</mat-icon>
					</button>
				</mat-form-field>
				<div class="m-l-10 d-flex align-items-center">
					<button type="submit" mat-raised-button color="primary" style="margin-right: 10px">
						Search
					</button>
				</div>
			</form>
		</div>
	</div>
	<mat-progress-bar mode="indeterminate" color="accent" class="m-t-20" *ngIf="loading"></mat-progress-bar>
	<div fxFlex="100">
		<div class="responsive-table">
			<mat-table [dataSource]="dataSource || []" matSort>
				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef> Headset </mat-header-cell>
					<mat-cell *matCellDef="let element"> {{ element.headset_name.name }} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="date">
					<mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
					<mat-cell *matCellDef="let element"> {{ element.date_created | date:"dd/MM/yy" }} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="quantity_purchased">
					<mat-header-cell *matHeaderCellDef> Quantity Ordered </mat-header-cell>
					<mat-cell *matCellDef="let element"> {{ element.quantity_purchased }} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="quantity_used">
					<mat-header-cell *matHeaderCellDef> Quantity Used </mat-header-cell>
					<mat-cell *matCellDef="let element"> {{ element.quantity_used }} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="quantity_remaining">
					<mat-header-cell *matHeaderCellDef> Quantity Remaining </mat-header-cell>
					<mat-cell *matCellDef="let element"> {{ element.quantity_purchased - element.quantity_used }}
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
			</mat-table>
			<mat-paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
				[pageSizeOptions]="[10, 25, 50]" (page)="pageChanged($event)" #userPag></mat-paginator>
		</div>
	</div>